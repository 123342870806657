import React, {Component} from 'react'
import SplashBtn       from '../../components/SplashBtn'
import LoginForm       from '../../components/LoginForm'
import HeaderComponent from '../../components/HeaderComponent'
import './Splash.css'
import { getWindowHeight } from 'libs/utils'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import { NEWS_SECTIONS, NEWS_TYPES, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import moment from 'moment'


const LogoApp = "/cities/"+process.env.REACT_APP_CITY.toLowerCase()+'/logo-app.png'

// ----------------------------------------------------------------
//
//
export default class Splash extends Component {

  // ----------------------------------------------------------------
  //
  //
  constructor(props) {
    super(props)

    this.state = {
      minHeight: 0,
      news: []
    }

    this.handleResize = this.handleResize.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    if ( prevProps != this.props ) {
      var news = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERLOGIN.value)      
      this.setState({ news: news.data})
    }
  }


  // ----------------------------------------------------------------
  //
  //
  async componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ minHeight : getWindowHeight()  })
    var news = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERLOGIN.value)    
    this.setState({ news: news.data})
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize ( ) {        
    this.setState({ "minHeight" : getWindowHeight() })    
  }
  

  // ----------------------------------------------------------------
  //
  //
  render () {
    const {onPasswordForgot, onSubmit, onRegisterClick, onSpidClick, onCieClick, onSendConfirmEmail } = this.props
    const {minHeight,news} = this.state

    return (<div>
      <HeaderComponent />
      <div className="main-container container" style={{ minHeight: minHeight-30 }}>
        <div className="row" >

          <div className="col-md-6" >
            <div className="panel panel-default splash-registration-div" style={{ minHeight:"40em"}}>
              <div className="splash-registration">
                <a href={"http://"+process.env.REACT_APP_MANAGER_URL} target="_new" alt="" className="btn-social btn-outline"><img alt="" className="splash-registration-logo" src={LogoApp} /></a>
              </div>
              <div className="splash-registration" style={{padding: 10}}>
                Benvenuto nello sportello on-line del Piano Sosta della città di {process.env.REACT_APP_CITYL}.                
              </div>
              <div className="splash-registration" style={{padding: '0 10px'}} >
                {news != null && news.length > 0 && 
                  <Carousel
                    autoPlay
                    interval={8000}
                    infiniteLoop
                    stopOnHover
                    emulateTouch
                    showThumbs={false}
                  >
                  { news.map(  n => 
                      <div key={"news-"+n} className={"boxLabel " + (getNewsStyle(n))} style={{padding: n.thumbImage != null ?'0' : '', backgroundColor: n.thumbImage!= null ? 'white': 'rgb(239, 233, 233)'}}>
                        <div>
                          {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                          { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                        </div>
                        {/* Decommentare quando verrà completata la funzionalità ADV
                        {n.thumbImage == null ? (
                        <div>
                          {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                          { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                        </div>
                        ):(
                          <img 
                            style={{width:'100%', height:'100%', objectFit:'contain'}} 
                            src={n.thumbImage}>
                          </img>
                        )} */}
                      </div>     
                  )} 
                </Carousel>
                }
              </div>
              <div className="splash-registration" >
                <SplashBtn className="mainBtn" onClick={onRegisterClick}>REGISTRATI</SplashBtn>
              </div>
            </div>

            <div className="row text-center align-top" style={{marginLeft: 10, marginRight: 10, marginBottom: 50}}>
              <span className="pas" >
                <a onClick={() => onSendConfirmEmail()}>
                  <i className="glyphicon glyphicon-lock">{}</i> Non hai ricevuto l'email di conferma registrazione? Clicca qui
                </a>                
              </span>
            </div>
            <br/>
          </div>

          <div className="col-md-4 col-md-offset-1" >
            <LoginForm
              onPasswordForgot={onPasswordForgot}
              onSubmit={onSubmit} 
              onSpidClick={onSpidClick}
              onCieClick={onCieClick}
              />
          </div>

        </div>
      </div>
    </div>
    )
  }
}
