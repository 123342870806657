import React, {Component} from 'react'


export default class SplashLoginBtn extends Component {
  render () {
    const {children} = this.props    

    return (
      <button {...this.props} className="mainBtnLarge" >
        <span style={{display: "flex", width:"100%", justifyContent : "space-between"}}>          
        { this.props.starticon &&                  
          <img alt="" className={this.props.classstarticon} src={this.props.starticon} />             
        }
        {children}
        </span>
      </button>
    )
  }
}
